<template>
  <div class="verify-wrapper">
    <div class="pannel_top"></div>
    <el-row :gutter="20">
      <el-col :span="15">
        <div class="service-query" style="padding-left: 10rem">
          <div style="width:800px; margin:auto;">
            <ul class="service-forms">
              <li c lass="form-group">
                <label class="form-label">证件类型：</label>
                <el-select
                  id="certificateType"
                  disabled
                  v-model="certificateType"
                  placeholder="请选择"
                  style="width:350px"
                >
                  <el-option
                    v-for="item in this.certTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </li>
              <li class="form-group">
                <label class="form-label" for="certificateNo">证件号：</label>
                <el-input
                  id="certificateNo"
                  disabled
                  ref="certificateNo"
                  v-model="certificateNo"
                  placeholder="请输入证件号"
                  style="width:350px"
                ></el-input>
              </li>
              <li class="form-group">
                <label class="form-label" for="userTrueName">姓名：</label>
                <el-input
                  id="userTrueName"
                  disabled
                  ref="userTrueName"
                  v-model="name"
                  placeholder="请输入姓名"
                  style="width:350px"
                ></el-input>
              </li>
              <li class="form-group">
                <label class="form-label" for="certificateNo">上传证件：</label>
                <!-- 其他证件 -->
                <el-upload
                  v-if="certificateType !== 'NI'"
                  style="margin-left:100px;width:250px;margin-bottom:20px;"
                  action="/api/fileUpload/file/upload"
                  list-type="picture-card"
                  :limit="picLimit"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="checkFileNum"
                  :on-preview="handlePictureCardPreview"
                  :on-success="uploadSuccessHandle"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <!-- 身份证 -->
                <el-upload
                  v-if="certificateType == 'NI'"
                  :class="['upload-card-1', picKeyList[0] ? 'disabled' : '']"
                  style="margin-left:100px;width:250px;margin-bottom:20px;"
                  action="/api/fileUpload/file/upload"
                  list-type="picture-card"
                  :limit="picLimit"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="checkFileNum"
                  :on-preview="handlePictureCardPreview"
                  :on-success="uploadSuccessHandle"
                  :on-remove="handleRemove"
                >
                  <span class="upload-btn">上传正面</span>
                </el-upload>
                <el-upload
                  v-if="certificateType == 'NI'"
                  :class="['upload-card-2', picKeyList[1] ? 'disabled' : '']"
                  style="margin-left:100px;width:250px;margin-bottom:20px;"
                  action="/api/fileUpload/file/upload"
                  list-type="picture-card"
                  :limit="picLimit"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="checkFileNum"
                  :on-preview="handlePictureCardPreview"
                  :on-success="uploadSuccessHandle"
                  :on-remove="handleRemove"
                >
                  <span class="upload-btn">上传反面</span>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </li>
              <li class="form-group">
                <label class="form-label"></label>
                <div style="text-align:center;width:50%;display: inline">
                  <el-button
                    type="danger"
                    @click="verify"
                    style="width:100px;border-radius:5px; margin-bottom: 10px;margin-top:20px;margin-left:80px;"
                  >确&nbsp;认</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="verify-tip">
      <h2>温馨提示</h2>
      <p>1 请确保您填写的内容正确无误，以免影响您购票或累积积分；</p>
      <p>2 请务必添加您的所有有效乘机证件的正反面进行认证，包括身份证、护照（国际航班必填）、军官证、港澳通行证；</p>
      <p>3 证件照认证申请提交后，将于1-3个工作日内完成审核；</p>
      <p>4 若审核不通过，请重新提交申请，如有疑问请拨打服务热线400-833-7777；</p>
    </div>
    <div class="pannel_bottom">
      <!-- <span>扫码快速认证，无需等待</span>
      <div class="pannel_bottom_wechat">
        <img style="width:100px;height:100px" src="../../assets/v.png" alt="">
      </div>-->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { verify } from "@/api/newsapi";
import { CURRENT_USER } from "@/store/mutation-types";

export default {
  data() {
    return {
      picKeyList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      picLimit: 1,
      name: "",
      certificateType: "",
      certificateNo: "",
      certTypeOptions: [
        {
          value: "NI",
          label: "身份证"
        },
        {
          value: "PP",
          label: "护照"
        },
        {
          value: "GM",
          label: "军官证"
        },
        {
          value: "HX",
          label: "回乡证"
        },
        {
          value: "TB",
          label: "台胞证"
        },
        {
          value: "TW",
          label: "台湾通行证"
        },
        {
          value: "HY",
          label: "海员证"
        },
        {
          value: "QT",
          label: "其它"
        }
      ]
    };
  },
  mounted() {
    this.currentUser = Vue.ls.get(CURRENT_USER);
    if (
      !this.currentUser ||
      this.currentUser.phone === undefined ||
      this.currentUser.phone.length <= 0
    ) {
      this.$router.push({ path: "/login", query: { t: new Date().getTime() } });
    }
    this.name = this.currentUser.name;
    this.certificateType = this.currentUser.certificateType;
    if (this.certificateType === "NI") {
      this.picLimit = 2;
    }
    if (this.certificateType === "PP") {
      this.picLimit = 1;
    }
    this.certificateNo = this.currentUser.certificateNo;
  },
  methods: {
    verify() {
      let typeName = "";
      for (let certType of this.certTypeOptions) {
        if (certType.value === this.certificateType) {
          typeName = certType.label;
          break;
        }
      }
      if (this.picKeyList.length !== this.picLimit) {
        this.$alert(`${typeName}请上传${this.picLimit}张图片`);
        return;
      }
      verify({ picKeyList: this.picKeyList }).then(res => {
        if (res.success) {
          this.$alert("申请成功", {
            confirmButtonText: "确定",
            callback: action => {
              this.$router.push({ path: "/member/memberHand" });
            }
          });
        } else {
          this.$message.error("申请失败," + res.errorMessage);
        }
      });
    },
    uploadSuccessHandle(response, file) {
      this.picKeyList.push(response);
    },
    checkFileNum() {
      let typeName = "";
      for (let certType of this.certTypeOptions) {
        if (certType.value === this.certificateType) {
          typeName = certType.label;
          break;
        }
      }
      this.$alert(`${typeName}请上传${this.picLimit}张图片`);
    },
    handleRemove(file) {
      let newKeyList = [];
      for (let picKeyListElement of this.picKeyList) {
        if (picKeyListElement !== file.response) {
          newKeyList.push(picKeyListElement);
        }
      }
      this.picKeyList = newKeyList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG或者PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return (isJPG || isPNG) && isLt1M;
    }
  }
};
</script>

<style scoped lang='less'>
.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
}

p {
  margin-top: 10px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}

.service-forms {
  padding-left: 7rem;
}

.form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.form-group .form-required {
  margin-left: 8px;
  font-size: 26px;
  font-weight: 700;
  padding-top: 4px;
  color: #e34c4c;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.strength-container .strength-bar {
  display: block;
  padding: 2px;
  height: 9px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
}

.strength-container .strength-bar div {
  display: block;
  float: left;
  width: 60px;
  height: 8px;
  background-color: #cdcdcd;
}

.strength-container .strength-bar div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.strength-container .strength-bar div:nth-child(2) {
  margin: 0 3px;
}

.strength-container .strength-bar div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.strength-container .strength-text {
  padding: 4px 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
}

.strength-container .strength-text span {
  display: inline-block;
  width: 33.33%;
}

.strength-container.weak > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.strong > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:last-child {
  background-color: #6aa001;
}

#regContainer .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ff103a;
  border-color: #ff103a;
}

#regContainer .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff103a;
}

#regContainer .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff103a;
}
.verify-wrapper {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  /* text-align: center; */
}
.pannel_top {
  width: 100%;
  height: 195px;
  background: url("../../assets/verify-top.jpg");
  background-size: contain;
}
.verify-tip {
  padding: 20px 10px;
  border-top: 1px solid #929a9f;
}
.verify-tip p {
  color: #929a9f;
}
.pannel_bottom {
  width: 100%;
  height: 195px;

  background: url("../../assets/verify-bottom.jpg");
  background-size: cover;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // position: relative;
  // span{
  //   font-size: 30px;
  //   font-weight: bold;
  //   color: #ffffff;
  //   display: flex;
  //   position: absolute;
  //   left: 100px;

  // }
  // &_wechat {
  //   position: absolute;
  //   right: 100px;
  //   height: 120px;
  //   width: 120px;
  //   background: #ffffff;
  //   border-radius: 10px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
.upload-btn {
  color: #d70039;
  border: 1px solid #d70039;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
